const { getApps, initializeApp } = require("firebase/app");

const firebaseConfig = require("../../../firebase-client-config.json");

const apps = getApps();
// eslint-disable-next-line import/no-mutable-exports
let firebaseApp;
if (!apps.length) {
  firebaseApp = initializeApp(firebaseConfig);
} else {
  // eslint-disable-next-line prefer-destructuring
  firebaseApp = apps[0];
}

module.exports = firebaseApp;
