import { render, staticRenderFns } from "./InternetTradingPolicyDetailsPopUp.vue?vue&type=template&id=049d512f&scoped=true&lang=pug&"
import script from "./InternetTradingPolicyDetailsPopUp.vue?vue&type=script&lang=ts&"
export * from "./InternetTradingPolicyDetailsPopUp.vue?vue&type=script&lang=ts&"
import style0 from "./InternetTradingPolicyDetailsPopUp.vue?vue&type=style&index=0&id=049d512f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "049d512f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InternetTradingPolicy: require('/workspace/portal/hosting/components/InternetTradingPolicy.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VCol,VDialog,VIcon,VRow,VSpacer})
