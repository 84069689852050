import { render, staticRenderFns } from "./CardError.vue?vue&type=template&id=0fd80b4e&scoped=true&lang=pug&"
import script from "./CardError.vue?vue&type=script&lang=ts&"
export * from "./CardError.vue?vue&type=script&lang=ts&"
import style0 from "./CardError.vue?vue&type=style&index=0&id=0fd80b4e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fd80b4e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LsButton: require('/workspace/packages/sharedUI/src/Button/Button.vue').default,LsButtonWrapper: require('/workspace/packages/sharedUI/src/Button/ButtonWrapper/ButtonWrapper.vue').default})


/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCard,VCardText,VCardTitle,VIcon})
