import { render, staticRenderFns } from "./InternetTradingPolicyPopUp.vue?vue&type=template&id=cddeb8b8&scoped=true&lang=pug&"
import script from "./InternetTradingPolicyPopUp.vue?vue&type=script&lang=ts&"
export * from "./InternetTradingPolicyPopUp.vue?vue&type=script&lang=ts&"
import style0 from "./InternetTradingPolicyPopUp.vue?vue&type=style&index=0&id=cddeb8b8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cddeb8b8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InternetTradingPolicyDetailsPopUp: require('/workspace/portal/hosting/components/InternetTradingPolicyDetailsPopUp.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VDialog,VIcon,VRow})
