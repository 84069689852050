import { render, staticRenderFns } from "./InfoBox.vue?vue&type=template&id=8045414e&scoped=true&lang=pug&"
import script from "./InfoBox.vue?vue&type=script&lang=ts&"
export * from "./InfoBox.vue?vue&type=script&lang=ts&"
import style0 from "./InfoBox.vue?vue&type=style&index=0&id=8045414e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8045414e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LsIcon: require('/workspace/packages/sharedUI/src/Icon/Icon.vue').default})
