import { render, staticRenderFns } from "./portal.vue?vue&type=template&id=ce64edb0&scoped=true&lang=pug&"
import script from "./portal.vue?vue&type=script&lang=ts&"
export * from "./portal.vue?vue&type=script&lang=ts&"
import style0 from "./portal.vue?vue&type=style&index=0&id=ce64edb0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce64edb0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CustomizableOverlay: require('/workspace/portal/hosting/components/CustomizableOverlay.vue').default,TheTopNavigation: require('/workspace/portal/hosting/components/TheTopNavigation.vue').default,LsAlert: require('/workspace/packages/sharedUI/src/Alert/Alert.vue').default,Loading: require('/workspace/portal/hosting/components/Loading/Loading.vue').default,NavigationDrawer: require('/workspace/portal/hosting/components/NavigationDrawer/NavigationDrawer.vue').default,SeasonalChristmasNavigation: require('/workspace/portal/hosting/components/seasonal/SeasonalChristmasNavigation.vue').default,LsBanner: require('/workspace/packages/sharedUI/src/Banner/Banner.vue').default,LsCardError: require('/workspace/packages/sharedUI/src/Card/CardError.vue').default,LsInfoBox: require('/workspace/packages/sharedUI/src/InfoBox/InfoBox.vue').default,LsInputGeneric: require('/workspace/packages/sharedUI/src/Input/InputGeneric/InputGeneric.vue').default,LsDialogGeneric: require('/workspace/packages/sharedUI/src/Dialog/DialogGeneric/DialogGeneric.vue').default,InternetTradingPolicyPopUp: require('/workspace/portal/hosting/components/InternetTradingPolicyPopUp.vue').default,Splash: require('/workspace/portal/hosting/components/Splash.vue').default,SignIn: require('/workspace/portal/hosting/components/signIn/SignIn.vue').default,SignInWithEmail: require('/workspace/portal/hosting/components/signIn/SignInWithEmail.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VApp,VFooter,VMain,VNavigationDrawer,VTextarea})
