import { render, staticRenderFns } from "./CustomizableOverlay.vue?vue&type=template&id=e2f3e868&lang=pug&"
import script from "./CustomizableOverlay.vue?vue&type=script&lang=ts&"
export * from "./CustomizableOverlay.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LsAlert: require('/workspace/packages/sharedUI/src/Alert/Alert.vue').default,LsDialogInfo: require('/workspace/packages/sharedUI/src/Dialog/DialogInfo.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VOverlay,VProgressCircular})
