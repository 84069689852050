import { render, staticRenderFns } from "./Splash.vue?vue&type=template&id=51e1b3ce&scoped=true&lang=pug&"
import script from "./Splash.vue?vue&type=script&lang=ts&"
export * from "./Splash.vue?vue&type=script&lang=ts&"
import style0 from "./Splash.vue?vue&type=style&index=0&id=51e1b3ce&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51e1b3ce",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LogoLoadsureTagline: require('/workspace/portal/hosting/components/LogoLoadsureTagline.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VLayout})
