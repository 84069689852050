import { render, staticRenderFns } from "./NavigationDrawer.vue?vue&type=template&id=2f2fd5ee&lang=pug&"
import script from "./NavigationDrawer.vue?vue&type=script&lang=ts&"
export * from "./NavigationDrawer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CertificateNavigation: require('/workspace/portal/hosting/components/certificate/CertificateNavigation.vue').default,ClaimNavigation: require('/workspace/portal/hosting/components/claim/ClaimNavigation.vue').default,NavigationDrawerAddressBook: require('/workspace/portal/hosting/components/NavigationDrawer/NavigationDrawerAddressBook.vue').default,NavigationDrawerAdmin: require('/workspace/portal/hosting/components/NavigationDrawer/NavigationDrawerAdmin.vue').default,IntegrationNavigation: require('/workspace/portal/hosting/components/integration/IntegrationNavigation.vue').default,UnderwriterNavigationDrawer: require('/workspace/portal/hosting/components/underwriter/UnderwriterNavigationDrawer.vue').default,AboutNavigation: require('/workspace/portal/hosting/components/about/AboutNavigation.vue').default})


/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VList,VListItem,VListItemContent,VListItemTitle})
