import {
  Ref,
  computed,
  inject,
  provide,
  ref,
  useContext
} from "@nuxtjs/composition-api";
import { getCountryItems } from "@loadsure/core/";
import { iItem } from "@loadsure/utils";

export const CHRISTMAS = 12;
export const HALLOWEEN = 10;

export function useSiteConfigurationProvide() {
  const {
    $axios,
    $config: { apiUrl },
    $sentry
  } = useContext();

  const shortName: Ref<string> = ref("");
  const name: Ref<string> = ref("");
  const isWhitelabel: Ref<boolean> = ref(false);
  const logos: Ref<any> = ref({});
  const issueMasterPolicies: Ref<boolean> = ref(false);
  const partnerCountries: Ref<string[]> = ref([]);
  const internetTradingPolicy: Ref<any> = ref("");
  const allowQuebecPickUp: Ref<boolean> = ref(false);
  const allowOceanCargoProduct: Ref<boolean> = ref(false);
  const portalConfigCurrencies: Ref<string[]> = ref([]);

  async function loadConfiguration() {
    try {
      const configuration = await $axios.$get(`${apiUrl}/api/portalConfig`);
      if (configuration) {
        shortName.value = configuration.shortName;
        name.value = configuration.name;
        isWhitelabel.value = configuration.isWhitelabel;
        logos.value = configuration.logos;
        partnerCountries.value = configuration.countries.partnerCountries;
        internetTradingPolicy.value =
          configuration.legal?.internetTradingPolicy;
        allowQuebecPickUp.value = configuration.allowQuebecPickUp;
        allowOceanCargoProduct.value = configuration.allowOceanCargoProduct;
        portalConfigCurrencies.value = configuration.countries.portalCurrencies;
      }
    } catch (error) {
      $sentry?.captureException(`Error loading configuration: ${error}`);
    }
  }

  loadConfiguration();

  function getLogo(type: string) {
    return logos.value?.[type];
  }

  const partnerCountryItems = computed(() =>
    getCountryItems(partnerCountries.value)
  );

  provide("shortName", shortName);
  provide("name", name);
  provide("isWhitelabel", isWhitelabel);
  provide("getLogo", getLogo);
  provide("partnerCountries", partnerCountries);
  provide("internetTradingPolicy", internetTradingPolicy);
  provide("allowQuebecPickUp", allowQuebecPickUp);
  provide("allowOceanCargoProduct", allowOceanCargoProduct);
  provide("partnerCountryItems", partnerCountryItems);
  provide("portalConfigCurrencies", portalConfigCurrencies);
  provide("issueMasterPolicies", issueMasterPolicies);

  return { loadConfiguration };
}

export function useSiteConfigurationInject() {
  const shortName = inject("shortName") as Ref<string>;
  const name = inject("name") as Ref<string>;
  const isWhitelabel = inject("isWhitelabel") as Ref<boolean>;
  const allowQuebecPickUp = inject("allowQuebecPickUp") as Ref<boolean>;
  const issueMasterPolicies = inject("issueMasterPolicies") as Ref<boolean>;
  const allowOceanCargoProduct = inject(
    "allowOceanCargoProduct"
  ) as Ref<boolean>;
  const getLogo = inject("getLogo") as (type: string) => any;
  const partnerCountries = inject("partnerCountries") as Ref<string[]>;
  const portalConfigCurrencies = inject("portalConfigCurrencies") as Ref<
    string[]
  >;
  const internetTradingPolicy = inject("internetTradingPolicy") as Ref<any>;
  const partnerCountryItems = inject("partnerCountryItems") as Ref<
    iItem<string>[]
  >;

  return {
    shortName,
    name,
    isWhitelabel,
    getLogo,
    partnerCountries,
    internetTradingPolicy,
    partnerCountryItems,
    allowQuebecPickUp,
    allowOceanCargoProduct,
    portalConfigCurrencies,
    issueMasterPolicies
  };
}

export function isActiveSeason(month) {
  const date = new Date();
  const currentMonth = date.getMonth() + 1;
  return currentMonth === month;
}
