import {
  Ref,
  computed,
  inject,
  provide,
  ref,
  watch
} from "@nuxtjs/composition-api";
import { myPolicyPath } from "@loadsure/core";
import { useApi } from "./useApi";
import { useCurrentUser } from "./useFireAuth";
import { useSiteConfigurationInject } from "./useSiteConfiguration";

export function useInternetTradingPolicyProvide() {
  const { internetTradingPolicy } = useSiteConfigurationInject();
  const { email, userPolicies } = useCurrentUser();
  // ref
  const showITP = ref(false);
  const showITPDetails = ref(false);
  const loading = ref(false);
  const { postApiData } = useApi();


  function viewITPDetails() {
    showITPDetails.value = true;
  }

  function closeITPDetails() {
    showITPDetails.value = false;
  }

  const itpPath = computed(() => {
    if (email.value) {
      return myPolicyPath(email.value, "internetTradingPolicy");
    }
    return undefined;
  });

  const itpVersion = computed(() => internetTradingPolicy?.value?.version);

  watch([itpPath, itpVersion], async () => {
    if (itpVersion.value && itpPath.value) {
      const acceptedVersions = userPolicies.value?.userITP;
      if (acceptedVersions) {
        showITP.value = !acceptedVersions[itpVersion.value];
      } else {
        showITP.value = true;
      }
    } else {
      showITP.value = false;
      showITPDetails.value = false;
    }
  });

  async function updateInternetTradingPolicy(data: any): Promise<any> {
    const url = "/user/userPolicies";
    return postApiData(url, data);
  }

  async function acceptITP() {
    if (itpPath.value) {
      try {
        loading.value = true;
        const doc = {
          acceptedVersions: {
            [`${itpVersion.value}`]: {
              date: new Date().toISOString()
            }
          }
        };
        const postData = {"policyType": "internetTradingPolicy", doc };
        await updateInternetTradingPolicy(postData);
        showITP.value = false;
        return;
      } catch (error) {
        console.error(error);
      } finally {
        loading.value = false;
      }
    }
  }

  

  const downloadUrl = computed(() => internetTradingPolicy.value?.url);

  provide("viewITPDetails", viewITPDetails);
  provide("closeITPDetails", closeITPDetails);
  provide("acceptITP", acceptITP);
  provide("showITP", showITP);
  provide("showITPDetails", showITPDetails);
  provide("loading", loading);
  provide("downloadUrl", downloadUrl);

  return {
    showITP,
    showITPDetails
  };
}

export function useInternetTradingPolicyInject() {
  const showITP = inject("showITP") as Ref<boolean>;
  const showITPDetails = inject("showITPDetails") as Ref<boolean>;
  const acceptITP = inject("acceptITP");
  const viewITPDetails = inject("viewITPDetails");
  const closeITPDetails = inject("closeITPDetails");
  const loading = inject("loading") as Ref<boolean>;
  const downloadUrl = inject("downloadUrl") as Ref<string>;

  return {
    showITP,
    showITPDetails,
    acceptITP,
    viewITPDetails,
    closeITPDetails,
    loading,
    downloadUrl
  };
}
