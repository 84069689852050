import { render, staticRenderFns } from "./default.vue?vue&type=template&id=937b441a&scoped=true&lang=pug&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"
import style0 from "./default.vue?vue&type=style&index=0&id=937b441a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "937b441a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CustomizableOverlay: require('/workspace/portal/hosting/components/CustomizableOverlay.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
installComponents(component, {VApp})
