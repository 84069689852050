/* eslint-disable import/prefer-default-export, import/no-extraneous-dependencies */
import { AxiosRequestConfig } from "axios";
import { Ref, ref, useContext } from "@nuxtjs/composition-api";
import { debouncePromise } from "@loadsure/utils";
import { getApiGetUrl } from "@loadsure/core";
import { useCurrentUser } from "./useFireAuth";

export const optionalApiTokenProps = () => ({
  apiToken: { type: String, required: false },
  partnerId: { type: String, required: false },
  playground: { type: Boolean, default: false }
});

export function useApi(
  apiToken?: string,
  partnerId?: string,
  playground?: boolean,
  complementUrl: string = "api"
) {
  const { authUser, userAccess } = useCurrentUser();

  const {
    $axios,
    $sentry,
    $config: { apiUrl }
  } = useContext();

  const getFullUrl = (path: string) =>
    new URL(
      `${complementUrl}${path.startsWith("/") ? "" : "/"}${path}`,
      apiUrl
    ).toString();

  const getAuth = async () => {
    const currPartnerId = partnerId ?? userAccess.value?.partnerId;
    let currToken = apiToken ?? "";
    if (!currToken && authUser.value) {
      currToken = await authUser.value.getIdToken();
    }
    if (currToken) {
      return {
        apiToken: currToken,
        partnerId: currPartnerId
      };
    }
    throw Error("Cannot authorize");
  };

  const getApiUrl = async (path: string, aliasToken = "t") => {
    const pathStart = getFullUrl(path);

    const auth = await getAuth();
    return getApiGetUrl(pathStart, auth.apiToken, auth.partnerId, aliasToken);
  };

  const getAxiosConfig = async (): Promise<AxiosRequestConfig> => {
    const auth = await getAuth();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${auth.apiToken}`,
        Playground: `${!!playground}`
      }
    };
    if (auth.partnerId) {
      config.headers.PartnerId = auth.partnerId;
    }
    return config;
  };

  const apiGetIsValid = (
    pathFn: () => string,
    isValidFn: (data: any) => boolean,
    setLoading: (value: boolean) => void = () => undefined
  ) => {
    const validator: Ref<() => Promise<boolean> | boolean> = ref(() => true);

    getAxiosConfig().then((config) => {
      const fn = async () => {
        setLoading(true);
        try {
          const url = getFullUrl(pathFn());
          const data = await $axios.$get(url, config);
          return isValidFn(data);
        } catch (error) {
          return false;
        } finally {
          setLoading(false);
        }
      };
      validator.value = debouncePromise(fn, 500);
    });

    return validator;
  };

  async function getApiData(
    path: string,
    responseType?: "blob",
    params?: Object,
    throwError: boolean = false
  ) {
    try {
      const url = getFullUrl(path);
      const config = await getAxiosConfig();
      config.params = params;
      if (responseType === "blob") {
        config.responseType = "blob";
        return $axios.get(url, config);
      }
      const response = await $axios.$get(url, config);
      return response;
    } catch (error) {
      $sentry?.captureException(error);
      if (throwError) return error;
    }
    return undefined;
  }

  async function postApiData(
    path: string,
    data?: any,
    onUploadProgress: (progressEvent: any) => void = () => undefined,
    contentType?: string,
    throwError: boolean = false
  ) {
    try {
      const url = getFullUrl(path);
      const config = await getAxiosConfig();
      if (contentType) {
        config.headers["Content-Type"] = contentType;
      }
      const response = await $axios.$post(url, data, {
        ...config,
        onUploadProgress
      });
      return response;
    } catch (error) {
      $sentry?.captureException(error);
      if (throwError) throw error;
    }
    return undefined;
  }

  async function putApiData(
    path: string,
    data: any,
    onUploadProgress: (progressEvent: any) => void = () => undefined,
    contentType?: string,
    throwError: boolean = false
  ) {
    try {
      const url = getFullUrl(path);
      const config = await getAxiosConfig();
      if (contentType) {
        config.headers["Content-Type"] = contentType;
      }
      const response = await $axios.$put(url, data, {
        ...config,
        onUploadProgress
      });
      return response;
    } catch (error) {
      $sentry?.captureException(error);
      if (throwError) throw error;
    }
    return undefined;
  }

  async function deleteApiData(path: string, throwError: boolean = false) {
    try {
      const url = getFullUrl(path);
      const config = await getAxiosConfig();
      const response = await $axios.$delete(url, config);
      return response;
    } catch (error) {
      $sentry?.captureException(error);
      if (throwError) throw error;
    }
    return undefined;
  }

  const getApiDataRef = (
    path: string,
    mapFn?: (value: any) => any,
    filterFn?: (value: any) => boolean,
    defaultValue: any = [],
    mapResponseFn?: (values: any) => any
  ) => {
    const isLoading: Ref<boolean> = ref(false);
    const loaded: Ref<boolean> = ref(false);
    const items: Ref<any> = ref(defaultValue);

    function load() {
      if (!loaded.value) {
        isLoading.value = true;
        getApiData(path)
          .then((response) => {
            let newItems = mapResponseFn ? mapResponseFn(response) : response;
            if (mapFn) {
              newItems = newItems.map(mapFn);
            }
            if (filterFn) {
              newItems = newItems.filter(filterFn);
            }
            items.value = newItems;
          })
          .catch((error) => {
            items.value = defaultValue;
            // todo fix this
            $sentry?.captureException(`API Error ${path}: ${error}`);
          })
          .finally(() => {
            loaded.value = true;
            isLoading.value = false;
          });
      }

      return items;
    }

    return {
      isLoading,
      loaded,
      items,
      load
    };
  };

  return {
    apiGetIsValid,
    getApiUrl,
    getApiData,
    postApiData,
    putApiData,
    getApiDataRef,
    deleteApiData
  };
}
