// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/header_halloween.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/halloween_600.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".seasonal-halloween[data-v-66e7cf4c]{position:absolute;top:0;left:0;width:100%;height:100%}.main-header__halloween[data-v-66e7cf4c]{position:absolute;left:200px;width:100%;min-width:930px;height:calc(100% - 2px);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-repeat:repeat-x;opacity:0;animation:halloweenAnimation-data-v-66e7cf4c 1s forwards;animation-delay:2s}.main-header__halloween--partner-logo[data-v-66e7cf4c]{left:300px}@media(max-width:600px){.main-header__halloween[data-v-66e7cf4c]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");min-width:600px;left:0}}.main-header__halloween-witch-hat[data-v-66e7cf4c]{position:absolute;top:0;right:0;animation:halloweenAnimation-data-v-66e7cf4c 1s forwards;animation-delay:1s;opacity:0}@keyframes halloweenAnimation-data-v-66e7cf4c{0%{opacity:0;transform:scale(.99)}to{opacity:1;transform:scale(1)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
